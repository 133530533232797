import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import { Parallax } from "react-parallax"
import BgImage from "../../static/img/bg6cropped.jpg"
import BlogImage1 from "../../static/img/blogimage1.jpg"
import BlogImage2 from "../../static/img/blogimage2.jpg"
import BlogImage3 from "../../static/img/blogimage3.jpg"
import BlogImage4 from "../../static/img/blogimage4.jpg"
import BlogImage5 from "../../static/img/blogimage5.jpg"
import BlogImage6 from "../../static/img/blogimage6.jpg"

const CaseStudiesPage = () => (
  <Layout>
    <Parallax bgImage={BgImage} className="text-center parallax-secondary">
      <div className="container-fluid">
        <div className="row">
          <div className="col" />
          <div className="col-md-7">
            <h1 className="text-white wow fadeInDown">CASE STUDIES</h1>
            <h3 className="text-white wow fadeInUp subtitle">
              Salvex is a global auction platform that handles the sale of
              surplus, salvage, scrap, damaged, bankrupt inventories for asset
              recovery, insurance, transportation
            </h3>
          </div>
          <div className="col" />
        </div>
      </div>
    </Parallax>
    <div className="container-fluid pb-4">
      <div className="container mb-4">
        <div className="row">
          <div className="col pt-4">
            <h1 className="h1 text-primary mt-4 text-center wow fadeInUp">
              Case Studies
            </h1>
          </div>
        </div>
        <div className="row">
          <div className="col-12">
            <div className="row mt-4 mb-4 bg-white border rounded">
              <div className="col-sm-8 col-md-3 col-lg-4 text-center wow fadeInLeft pt-4 pb-4 bg-alt">
                <img src={BlogImage1} className="img-thumbnail" />
              </div>
              <div className="col-sm-8 col-md-5 col-lg-8 wow fadeInRight pt-4 pb-4">
                <h2 className="h3 text-secondary mt-0 mb-0">
                  Salvex Recovers 19,000 Solar Panels in Honduras Insurance
                  Claim
                </h2>
                <p className="mt-4">
                  Salvex recovers 19,000 solar panels from a solar farm
                  insurance claim from a brush fire. A fire broke out in a large
                  solar park, damaging some of the panels that required removing
                  and shipping out of the country. We were able to find a buyer
                  who repurposed these panels in solar parks in the USA. The
                  buyer saved themselves hundreds of thousands of dollars in
                  using salvage panels for their project, instead of having new
                  ones manufactured. Salvex refurbished some of the burned
                  panels and was able to regain their usability for the buyer.
                  This is a sustainability solution created from a major
                  Venezuelan insurance company who was insuring panels in
                  Honduras and needed a comprehensive solution.
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-12">
            <div className="row mt-4 mb-4 bg-white border rounded">
              <div className="col-sm-8 col-md-3 col-lg-4 text-center wow fadeInLeft pt-4 pb-4 bg-alt">
                <img src={BlogImage2} className="img-thumbnail" />
              </div>
              <div className="col-sm-8 col-md-5 col-lg-8 wow fadeInRight pt-4 pb-4">
                <h2 className="h3 text-secondary mt-0 mb-0">
                  Salvex Sells 100,000 Feet of Structural Tubing in a
                  Sustainability Solution
                </h2>
                <p className="mt-4">
                  Salvex successfully handled the sale of 2 3/8, 2 7/8 and 3 1/2
                  pipe in a surplus sale for a major oil and gas company. The
                  buyer was able to repurpose this pipe in creative fencing and
                  structural applications, saving themselves thousands of
                  dollars in purchase costs of new materials. The ability for
                  the buyer to utilize this used pipe in a creative
                  sustainability solution, benefited everyone involved. This
                  effort was good for the environment, by avoiding mining,
                  manufacturing and milling of new pipe.
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-12">
            <div className="row mt-4 mb-4 bg-white border rounded">
              <div className="col-sm-8 col-md-3 col-lg-4 text-center wow fadeInLeft pt-4 pb-4 bg-alt">
                <img src={BlogImage3} className="img-thumbnail" />
              </div>
              <div className="col-sm-8 col-md-5 col-lg-8 wow fadeInRight pt-4 pb-4">
                <h2 className="h3 text-secondary mt-0 mb-0">
                  Salvex Sells 241 John Deere Engines as a Sustainability
                  Solution for Manufacture
                </h2>
                <p className="mt-4">
                  Salvex built a sustainability solution, by handling the sale
                  of 241 John Deere Engines, that were a part of an insurance
                  claim when the shelving that was storing them collapsed. The
                  buyer saved themselves thousands of dollars by repurposing
                  these engines as replacements for older equipment, creating a
                  sustainability solution beneficial to everyone. The buyer in
                  the Middle East no longer needed to buy new engines that would
                  have been manufactured. His ability to adapt to the
                  opportunity was good for the environment.
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-12">
            <div className="row mt-4 mb-4 bg-white border rounded">
              <div className="col-sm-8 col-md-3 col-lg-4 text-center wow fadeInLeft pt-4 pb-4 bg-alt">
                <img src={BlogImage4} className="img-thumbnail" />
              </div>
              <div className="col-sm-8 col-md-5 col-lg-8 wow fadeInRight pt-4 pb-4">
                <h2 className="h3 text-secondary mt-0 mb-0">
                  Salvex Recovers 44,064 Flat Bar in a Sustainability Solution
                  for a Major Distribution Company
                </h2>
                <p className="mt-4">
                  Salvex was called to handle the sale of 44,064 lbs of Pickled
                  and Oiled Flat Bar that was involved in an accident in Kansas.
                  The bar came off the truck and needed to be restacked and
                  chained to a new flat bed and stored at a wrecker yard. This
                  material could no longer be used by a sensitive manufacture
                  who needed perfect product. Salvex team researched the steel
                  industry for someone who could repurpose these flat bars for a
                  less sensitive application. We found a willing buyer in New
                  Jersey, who saved themselves thousands and avoided ordering
                  new product from the mill. By utilizing this steel with
                  scratches and dents, could still draw out the uses, and
                  creating a sustainability solution for the distributor.
                </p>
              </div>
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col-12">
            <div className="row mt-4 mb-4 bg-white border rounded">
              <div className="col-sm-8 col-md-3 col-lg-4 text-center wow fadeInLeft pt-4 pb-4 bg-alt">
                <img src={BlogImage5} className="img-thumbnail" />
              </div>
              <div className="col-sm-8 col-md-5 col-lg-8 wow fadeInRight pt-4 pb-4">
                <h2 className="h3 text-secondary mt-0 mb-0">
                  Salvex Sells Beechcraft E90 for Aviation Insurance Claim as a
                  Sustainability Solution
                </h2>
                <p className="mt-4">
                  Salvex was hired to sell an Beechcraft E90 that had a bad
                  landing. The aircraft sustained considerable damage and was
                  totaled. Salvex was able to secure a local buyer who also has
                  a need for the Beechcraft usable parts, saving themselves
                  thousands of dollars in repurposing these parts for another
                  aircraft. We were able to successfully complete a
                  sustainability solution for a major insurance company, keeping
                  this insured happy with the quick process.
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-12">
            <div className="row mt-4 mb-4 bg-white border rounded">
              <div className="col-sm-8 col-md-3 col-lg-4 text-center wow fadeInLeft pt-4 pb-4 bg-alt">
                <img src={BlogImage6} className="img-thumbnail" />
              </div>
              <div className="col-sm-8 col-md-5 col-lg-8 wow fadeInRight pt-4 pb-4">
                <h2 className="h3 text-secondary mt-0 mb-0">
                  Salvex Recovers Damaged Equipment and Builds Sustainability
                  Solution for Major Carrier
                </h2>
                <p className="mt-4">
                  Salvex was contacted to handle the sale of a 2018 Freightliner
                  Cascadia 125 tractor that was involved in a major accident.
                  The tractor sustained damage to the frame and the cab, making
                  this tractor totaled and not repairable. Salvex worked with
                  local repair shops to find someone who needed the engine and
                  other components to rebuild another truck. The customer saved
                  thousands of dollars repurposing these components instead
                  buying new ones. The speed of the sale, also saved the
                  insurance company hundreds of dollars in storage costs at the
                  wrecker's facility. This is an example of repurposing
                  equipment as a sustainability solution in the transportation
                  industry.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </Layout>
)

export default CaseStudiesPage
